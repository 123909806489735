import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import "./index.css";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login"));
const ForgotPassword = React.lazy(() => import("./views/pages/forgotPassword"));
const OtpForgotPass = React.lazy(() => import("./views/pages/otpForgotPass"));
const SetNewPassword = React.lazy(() => import("./views/pages/setNewPassword"));

const CmsAbout = React.lazy(() => import("./views/pages/cms/about"));
const CmsPrivacyPolicy = React.lazy(() =>
  import("./views/pages/cms/privacyPolicy")
);
const CmsContact = React.lazy(() => import("./views/pages/cms/contact"));
const CmsTerms = React.lazy(() =>
  import("./views/pages/cms/termsAndConditions")
);
const CmsFaq = React.lazy(() => import("./views/pages/cms/faq"));

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route
          exact
          path="/forgot-password"
          name="Password Reset Page"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/verify-forgot-pass"
          name="Verify Forgot Pass"
          element={<OtpForgotPass />}
        />
        <Route
          exact
          path="/set-new-password"
          name="Set New Password"
          element={<SetNewPassword />}
        />

        <Route
          exact
          path="/cms/about-us"
          name="About Us"
          element={<CmsAbout />}
        />
        <Route
          exact
          path="/cms/privacy-policy"
          name="Privacy Policy"
          element={<CmsPrivacyPolicy />}
        />

        <Route exact path="/cms/faq" name="CMS FAQ" element={<CmsFaq />} />
        <Route
          exact
          path="/cms/terms-and-conditions"
          name="CMS Terms & Conditions"
          element={<CmsTerms />}
        />

        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  );
};

export default App;
